import { store } from "@/store/store";
import { tarea } from "@/shared/dtos/tareas/tarea";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "plantilla_tareasModule",
  store,
  dynamic: true,
})
class plantilla_tareasModule extends VuexModule {
  public plantilla_tareass: tarea[] = [];
  public plantilla_tareas: tarea = new tarea();

  @Action({ commit: "onGetplantilla_tareass" })
  public async getplantilla_tareass() {
    return await ssmHttpService.get(API.plantilla_tareas);
  }

  @Action({ commit: "onGetplantilla_tareas" })
  public async getplantilla_tareas(id: any) {
    return await ssmHttpService.get(API.plantilla_tareas + "/" + id);
  }

  @Action
  public async guardarplantilla_tareas(plantilla_tareas: tarea) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.plantilla_tareas,
      plantilla_tareas.toJson()
    );
  }

  @Action
  public async modificarplantilla_tareas(plantilla_tareas: tarea) {
    return await ssmHttpService.put(API.plantilla_tareas+ "/" + plantilla_tareas.id, plantilla_tareas);
  }

  @Action
  public async eliminarplantilla_tareas(plantilla_tareas: tarea) {
    return await ssmHttpService.delete(
      API.plantilla_tareas + "/" + plantilla_tareas.id,
      null,
      false
    );
  }

  @Mutation
  public onGetplantilla_tareass(res: tarea[]) {
    this.plantilla_tareass = res ? res.map((x) => new tarea(x)) : [];
  }

  @Mutation
  public onGetplantilla_tareas(res: tarea) {
    this.plantilla_tareas = new tarea(res);
  }
}
export default getModule(plantilla_tareasModule);
