import { store } from "@/store/store";
import { periodicidad_plantilla_tarea } from "@/shared/dtos/platillas/periodicidad_plantilla_tarea";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "periodicidad_plantilla_tareaModule",
  store,
  dynamic: true,
})
class periodicidad_plantilla_tareaModule extends VuexModule {
  public periodicidad_plantilla_tareas: periodicidad_plantilla_tarea[] = [];
  public periodicidad_plantilla_tarea: periodicidad_plantilla_tarea = new periodicidad_plantilla_tarea();

  @Action({ commit: "onGetperiodicidad_plantilla_tareas" })
  public async getperiodicidad_plantilla_tareas() {
    return await ssmHttpService.get(API.periodicidad_plantilla_tarea);
  }

  @Action({ commit: "onGetperiodicidad_plantilla_tarea" })
  public async getperiodicidad_plantilla_tarea(id: any) {
    return await ssmHttpService.get(
      API.periodicidad_plantilla_tarea + "/" + id
    );
  }

  @Action
  public async guardarperiodicidad_plantilla_tarea(
    periodicidad_plantilla_tarea: periodicidad_plantilla_tarea
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.periodicidad_plantilla_tarea,
      periodicidad_plantilla_tarea.toJson()
    );
    this.getperiodicidad_plantilla_tareas();
  }

  @Action
  public async modificarperiodicidad_plantilla_tarea(
    periodicidad_plantilla_tarea: periodicidad_plantilla_tarea
  ) {
    await ssmHttpService.put(
      API.periodicidad_plantilla_tarea,
      periodicidad_plantilla_tarea
    );
    this.getperiodicidad_plantilla_tareas();
  }

  @Action
  public async eliminarperiodicidad_plantilla_tarea(
    periodicidad_plantilla_tarea: periodicidad_plantilla_tarea
  ) {
    await ssmHttpService.delete(
      API.periodicidad_plantilla_tarea + "/" + periodicidad_plantilla_tarea.id,
      null,
      false
    );
    this.getperiodicidad_plantilla_tareas();
  }

  @Mutation
  public onGetperiodicidad_plantilla_tareas(
    res: periodicidad_plantilla_tarea[]
  ) {
    this.periodicidad_plantilla_tareas = res
      ? res.map((x) => new periodicidad_plantilla_tarea(x))
      : [];
  }

  @Mutation
  public onGetperiodicidad_plantilla_tarea(res: periodicidad_plantilla_tarea) {
    this.periodicidad_plantilla_tarea = new periodicidad_plantilla_tarea(res);
  }
}
export default getModule(periodicidad_plantilla_tareaModule);
