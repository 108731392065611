


































































































































































































import { RouterNames } from "@/router/routernames";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { plantillas_cabezera } from "@/shared/dtos/platillas/plantillas_cabezera";
import { tarea } from "@/shared/dtos/tareas/tarea";
import empresaModule from "@/store/modules/empresa-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import plantillas_cabezeraModule from "@/store/modules/plantillas_cabezera-module";
import periodicidad_plantilla_tareaModule from "@/store/modules/periodicidad_plantilla_tarea-module";
import plantilla_tareasModule from "@/store/modules/plantilla_tareas-module";
import { Component, Vue } from "vue-property-decorator";
import invetarioModule from "@/store/modules/invetario-module";
import { invetario } from "@/shared/dtos/invetario";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
    TareaPlatillaFormulario: () =>
      import("@/views/plantillas/tareas/TareaPlatillaFormulario.vue"),
    TableSelect: () => import("@/components/DataTableSelect/TableSelect.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class PlantillasTareasFormulario extends Vue {
  public show_dialog_tarea = false;
  public enums_estados: enumeracion[] = [];
  public enums_tipo: enumeracion[] = [];
  public tarea_edicion: tarea = new tarea({ id: -1 });
  public show_table_select: boolean = false;
  public data_selected: invetario[] = [];

  created() {
    this.refresh();

    /*
    6	Estados de las tareas
    7	Tipo de tareas
    */
    empresaModule.getempresas();
    enumeracionModule.getenumeraciones_fromtipo(6).then((x: enumeracion[]) => {
      this.enums_estados = x;
    });
    enumeracionModule.getenumeraciones_fromtipo(7).then((x: enumeracion[]) => {
      this.enums_tipo = x;
    });
    periodicidad_plantilla_tareaModule.getperiodicidad_plantilla_tareas();
  }

  public get datasource() {
    return plantillas_cabezeraModule.plantillas_cabezera;
  }

  public get periodicidad() {
    return periodicidad_plantilla_tareaModule.periodicidad_plantilla_tareas;
  }

  public get data_table() {
    return invetarioModule.invetarios;
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public data_estado(id: number) {
    let rest = this.enums_estados.find((x) => x.id === id);
    if (rest === undefined) {
      return "";
    }
    return rest.nombre;
  }

  public data_tipo(id: number) {
    let rest = this.enums_tipo.find((x) => x.id === id);
    if (rest === undefined) {
      return "";
    }
    return rest.nombre;
  }

  public data_proveedor(id: number) {
    let rest = empresaModule.empresas.find((x) => x.id === id);
    if (rest === undefined) {
      return "";
    }
    return rest.nombre;
  }

  content_copy() {
    this.show_table_select = true;
  }
  cancelar_duplicados_inventario() {
    this.show_table_select = false;
    this.data_selected = [];
  }

  async aceptar_duplicados_inventario() {
    var add_tareas = [];
    for (let i = 0; i < this.data_selected.length; i++) {
      let inventario = this.data_selected[i];
      for (let k = 0; k < this.datasource.tareas_asociadas.length; k++) {
        let tarea_inventario = new tarea(this.datasource.tareas_asociadas[k]);
        tarea_inventario.id = 0;
        tarea_inventario.id_inventario = inventario.id;
        tarea_inventario.id_cabezera_plantilla = this.datasource.id;
        add_tareas.push(tarea_inventario);
      }
    }
    for (let k = 0; k < add_tareas.length; k++) {
      await plantilla_tareasModule.guardarplantilla_tareas(
        new tarea(add_tareas[k])
      );
      this.datasource.tareas_asociadas.push(add_tareas[k]);
    }
    this.refresh();
    this.cancelar_duplicados_inventario();
  }

  add() {
    this.show_dialog_tarea = true;
    this.tarea_edicion.id = -1;
  }
  refresh() {
    invetarioModule.getinvetarios();
    this.show_dialog_tarea = false;
    this.tarea_edicion.id = -1;
    if (this.is_new()) {
      plantillas_cabezeraModule.onGetplantillas_cabezera(
        new plantillas_cabezera()
      );
    } else {
      plantillas_cabezeraModule.getplantillas_cabezera(this.$route.params.id);
    }
  }
  editar(tarea: tarea) {
    this.tarea_edicion = tarea;
    this.show_dialog_tarea = true;
  }
  duplicar_linea(item: tarea) {
    item.id = 0;
    item.id_cabezera_plantilla = this.datasource.id;
    plantilla_tareasModule
      .guardarplantilla_tareas(new tarea(item))
      .then(() => this.refresh());
  }

  eliminar_linea(tarea: tarea) {
    plantilla_tareasModule
      .eliminarplantilla_tareas(tarea)
      .then(() => this.refresh());
  }

  aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          plantillas_cabezeraModule
            .guardarplantillas_cabezera(this.datasource)
            .then(() => this.cancelar());
        } else {
          plantillas_cabezeraModule
            .modificarplantillas_cabezera(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }
  eliminar() {
    plantillas_cabezeraModule
      .eliminarplantillas_cabezera(this.datasource)
      .then(() => this.cancelar());
  }
  cancelar() {
    this.$router.push({
      name: RouterNames.administracion_tareas_plantillas_lista,
    });
  }
}
